import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../config/firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import CommentsDialog from '../../components/comments/CommentsDialog'; // Adjust the path as needed
import Loading from '../../components/loading/Loading';
import GoToPage from '../../components/goToPage/goToPage';
import './VideoDetail.css';

const VideoDetail = () => {
    const { sectionId, videoId } = useParams(); // Assume you have sectionId in your route params
    const [video, setVideo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showComments, setShowComments] = useState(false);
    const [showVideo, setShowVideo] = useState(false);

    const handleRightClick = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        const fetchVideo = async () => {
            setIsLoading(true);
            const videoDocRef = doc(db, "sections", sectionId, "videos", videoId);
            const videoDoc = await getDoc(videoDocRef);
            if (videoDoc.exists()) {
                setVideo(videoDoc.data());
            } else {
                console.log("No such video!");
            }
            setIsLoading(false);
        };

        fetchVideo();
    }, [sectionId, videoId]);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className='videoDetailWrapper'>
            {video && <>
            <GoToPage />
            <h1 onClick={handleRightClick}>{video.title}</h1>
                <h1>{video.name}</h1>
                <div className='videoWrapper'>
                    {!showVideo && <img className='mainThumbnail' src={video.thumbnailURL} alt="Video Thumbnail" onClick={() => setShowVideo(true)} /> }
                    {showVideo && <video className='mainVideo' src={video.videoURL} controls autoPlay onContextMenu={handleRightClick} /> }
                </div>
                <button className='mainBtn' onClick={() => setShowComments((prevShowComments) => !prevShowComments)}>{showComments ? 'Hide' : 'Show'} comments</button>
                {showComments && (
                    <CommentsDialog
                        videoId={videoId}
                        onClose={() => setShowComments(false)}
                    />
                )}
                </>
            }
        </div>
    );
};

export default VideoDetail;
