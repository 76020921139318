// App.js
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Home } from "./pages/home/index";
import AdminPage from "./pages/admin/index"; // Import the new component
import AdminTools from "./pages/admintools/index"; // Import the new component
import Sections from './pages/sections/index'; // Import the Sections component
import SectionDetail from './pages/sectionDetail/index';
import AddVideo  from './pages/addVideo/index';
import VideoDetail from './pages/videoDetail/index';
import Layout from './components/layout/Layout';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index exact element={<Home />} />
            <Route path="/admin" element={<AdminPage />} /> {/* Add this line */}
            <Route path="/admintools" element={<AdminTools />} /> {/* Add this line */}
            <Route path="/admintools/sections" element={<Sections />} />
            <Route path="/admintools/sections/:sectionId" element={<SectionDetail />} />
            <Route path="/admintools/sections/:sectionId/addvideo" element={<AddVideo />} />

            <Route path="/section/:sectionId/video/:videoId" element={<VideoDetail />} /> {/* Updated route */}
          </Route>
       </Routes>
      </Router>
    </div>
  );
}

export default App;
