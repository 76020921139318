import { onAuthStateChanged, signOut } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { auth } from "../../config/firebase-config";
import './AuthDetails.css';
import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

const AuthDetails = () => {
  const [authUser, setAuthUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
    });

    return () => {
      listen();
    };
  }, []);

  useEffect(() => {
    console.log(authUser);
  }, [authUser])

  const userSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log("sign out successful");
        navigate('');
        window.location.reload(); // Reload the page
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="authDetailsWrapper">
      {authUser && (
        <>
          <p className="userName"><FaUserCircle/>{authUser.displayName}</p>
          <button className="mainBtn signOutBtn" onClick={userSignOut}>Sign Out</button>
        </>
      )}
    </div>
  );
};

export default AuthDetails;