// AdminPage.js
import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, getDoc, deleteDoc } from "firebase/firestore";
import { db, auth } from "../../config/firebase-config";
import { useNavigate } from 'react-router-dom';
import GoToPage from '../../components/goToPage/goToPage';
import './admin.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../../components/loading/Loading';

const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!auth.currentUser) {
        // If no user is signed in, redirect to home or login page
        navigate('/');
        return;
      }

      const userDocRef = doc(db, "users", auth.currentUser.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists() && userDoc.data().permissions !== "admin") {
        // If the user is not an admin, redirect to home page
        navigate('/');
        return;
      }

      // If the user is an admin, fetch and display users
      setIsLoading(true);
      const querySnapshot = await getDocs(collection(db, "users"));
      setUsers(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      setIsLoading(false);
    };

    checkAdminStatus();
  }, [navigate]);

  const handlePermissionChange = async (userId, currentPermission) => {
    const newPermission = currentPermission === "none" ? "none" : "premium";
    const userDocRef = doc(db, "users", userId);

    try {
        await updateDoc(userDocRef, {
            permissions: newPermission
        });
        if (newPermission === "premium") {
          await updateDoc(userDocRef, {
            activeSessions: []
          });
        }

        // Update the local state to reflect the change
        setUsers(users.map(user => user.id === userId ? {...user, permissions: newPermission} : user));
    } catch (error) {
        console.error("Error updating permissions:", error);
    }
};

const handleDeleteUser = async (user) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this user?");
    if (confirmDelete) {
        try {
            await deleteDoc(doc(db, "users", user.id));
            toast.success(`User: ${user.displayName} deleted successfully`);
            // Update the local state to remove the user from the list
            setUsers(users.filter(u => u.id !== user.id));
        } catch (error) {
            toast.error(`Error occured, user was not deleted`);
            console.error("Error deleting user:", error);
        }
    }
};

if (isLoading) {
  return <Loading />;
}

return (
    <div className='adminPageWrapper'>
      <GoToPage />
      <h2 className='usersListTitle'>List of users:</h2>
      <div className='usersListWrapper'>
        {users.map((user) => (
          <div key={user.id} className="user-card">
            <p>Name: {user.displayName}</p>
            <p>Email: {user.email}</p>
            <p>Phone: {user.phone}</p>
            <p>Permissions: {user.permissions}</p>
            {user.permissions !== "admin" && (
              <div className='userButtonsWrapper'>
                <button className='mainBtn' onClick={() => handlePermissionChange(user.id, user.permissions === "none" ? "premium" : "none")}>
                  {user.permissions === "none" ? "Uplift Permissions" : "Decline Permissions"}
                </button>
                <button className='deleteBtn' onClick={() => handleDeleteUser(user)}>
                  Delete User
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
      <ToastContainer
            theme="dark"
            position= "top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
    </div>
  );
};



export default AdminPage;
