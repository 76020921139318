import React, { useEffect, useState } from 'react';
import SignIn from '../../components/auth/SignIn';
import SignUp from '../../components/auth/SignUp';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../config/firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import VideoGallery from '../../components/videos/VideoGallery';
import Loading from '../../components/loading/Loading';
import { collection, getDocs, query, where, orderBy, getDoc, doc } from 'firebase/firestore';
import './home.css';

function Home() {
    const [isAdmin, setIsAdmin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);
    const [sections, setSections] = useState([]);
    const navigate = useNavigate();
    const user = auth.currentUser;

    const toggleShowSignUp = () => {
        setShowSignUp((prevShowSignUp) => !prevShowSignUp);
    }

    const goToAdminPage = () => {
        navigate('/admin');
    };

    const navigateToAdminTools = () => {
        navigate('/admintools/sections');
    };

    const loginContent = () => {
        if(!user) {
            return showSignUp ? <SignUp toggleShowSignUp={toggleShowSignUp} /> : <SignIn toggleShowSignUp={toggleShowSignUp} />;
        }
        return null;
    }

    useEffect(() => {
        let isSubscribed = true; // to handle async state updates after component unmount
        setIsLoading(true);
    
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            let userPermissions = null;
    
            if (currentUser) {
                const userDoc = await getDoc(doc(db, "users", currentUser.uid));
                if (userDoc.exists()) {
                    userPermissions = userDoc.data().permissions;
                    setIsAdmin(userPermissions === "admin");
                }
            }
            console.log(userPermissions);
    
            let sectionQuery = query(collection(db, "sections"), orderBy("index"));
            if (userPermissions !== "premium" && userPermissions !== "admin") {
                sectionQuery = query(collection(db, "sections"), where("open", "==", true), orderBy("index"));
            }
    
            const querySnapshot = await getDocs(sectionQuery);
            let fetchedSections = [];
    
            for (const sectionDoc of querySnapshot.docs) {
                const sectionData = sectionDoc.data();
                sectionData.id = sectionDoc.id; // Save section ID in section data
        
                // Fetch associated videos for this section
                const videosSnapshot = await getDocs(collection(db, "sections", sectionDoc.id, "videos"));
                sectionData.videos = videosSnapshot.docs.map(videoDoc => ({
                    id: videoDoc.id,
                    ...videoDoc.data()
                }));
        
                fetchedSections.push(sectionData);
            }
    
            if (isSubscribed) {
                setSections(fetchedSections);
                setIsLoading(false);
            }
        });
    
        // Cleanup function to avoid state update on an unmounted component
        return () => {
            isSubscribed = false;
            unsubscribe(); // Unsubscribe from auth state listener when component unmounts
        };
    }, []);
    

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className="home">
            {loginContent()}
            {isAdmin && (
                <div className='adminButtonsWrapper'>
                    <button className='mainBtn' onClick={goToAdminPage}>Go to Admin Page</button>
                    <button className='mainBtn' onClick={navigateToAdminTools}>Go to Admin Tools</button>
                </div>
            )}
            <VideoGallery sections={sections} />
        </div>
    );
}

export { Home };
