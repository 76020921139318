
// SignIn.js or SignIn.tsx
import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../config/firebase-config";
import { getFunctions, httpsCallable } from 'firebase/functions';
import './auth.css';
import { checkValidations } from './validators';

async function getUserIP() {
  const response = await fetch('https://api.ipify.org?format=json');
  const data = await response.json();
  return data.ip;
}

async function getUserLocation() {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
        },
        error => {
          reject(error);
        }
      );
    } else {
      reject(new Error('Geolocation is not supported by this browser.'));
    }
  });
}

const SignIn = ({ toggleShowSignUp }) => {
  const initialValue = {email: '', password: ''};
  const [formData, setFormData] = useState(initialValue);
  const [formErrors, setFormErrors] = useState(initialValue);
  const [generalError, setGeneralError] = useState({noUser: false, noConnection: false});

  const handleFormInputChange = (e) => {
    setFormData((prevForm) => ({...prevForm, [e.target.name]: e.target.value}));
    setFormErrors(initialValue);
  }

  const handleSignIn = async (e) => {
    e.preventDefault();
    const { email, password } = formData;
    const newErrors = checkValidations(email, password);
    if (newErrors) {
      setFormErrors(newErrors);
      return;
    }
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const ip = await getUserIP();
      const location = await getUserLocation(); // Get user location
      const functions = getFunctions();
      const trackUserLogin = httpsCallable(functions, 'trackUserLogin');
      const result = await trackUserLogin({ ip, location });
      if (result.data.status === 'logged-out') {
        console.error('Logged out due to simultaneous login limit exceeded.');
      } else {
        console.log('Login successful.');
        setGeneralError({noUser: false, noConnection: false});
      }
    } catch (error) {
      let noUser = false;
      let noConnection = false;
      if (error && error.message && error.message.includes("invalid-login-credentials")) {
        noUser = true;
      }
      else {
        noConnection = true;
      }
      setGeneralError({noUser, noConnection});
      console.error(error);
    }
  };

  return (
    <div className="sign-container">
      <form onSubmit={handleSignIn}>
        <h1>Log in to your account</h1>
        <input
          className={formErrors.email ? 'formInput errorInput' : 'formInput'}
          type="email"
          placeholder="Enter your email"
          name="email"
          value={formData.email}
          onChange={(e) => handleFormInputChange(e)}
          autoComplete="off"
        />
        <p className={formErrors.email ? 'error' : 'noError'}>{formErrors.email}</p>
        <input
          className={formErrors.password ? 'formInput errorInput' : 'formInput'}
          type="password"
          placeholder="Enter your password"
          name="password"
          value={formData.password}
          onChange={(e) => handleFormInputChange(e)}
          autoComplete="off"
        />
        <p className={formErrors.password ? 'error' : 'noError'}>{formErrors.password}</p>
        <button className="mainBtn" type="submit">Log In</button>
      </form>
      <div>Need an account? <span className="signUp" onClick={() => toggleShowSignUp()}>SIGN UP</span></div>
      {generalError.noUser && <div className="generalError">No user found</div>}
      {generalError.noConnection && <div className="generalError">General error, please try again</div>}
    </div>
  );
};

export default SignIn;
