import React from 'react';
import './Layout.css';
import { Outlet } from "react-router-dom";
import Navbar from '../navbar/Navbar';


const Layout = () => {
    return (
        <>
          <Navbar />
          <Outlet />
        </>
      )
}

export default Layout;