import React from 'react';
import './Loading.css';

function Loading() {
  return (
    <div className='loadingWrapper'>
      Loading...
    </div>
  )
}

export default Loading
