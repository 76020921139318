import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, getDocs, doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db, storage  } from '../../config/firebase-config';
import { ref, deleteObject,  uploadBytes, getDownloadURL } from 'firebase/storage';
import GoToPage from '../../components/goToPage/goToPage';
import './sectionDetail.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../../components/loading/Loading';

const SectionDetail = () => {
  const { sectionId } = useParams();
  const [videos, setVideos] = useState([]);
  const [section, setSection] = useState(null);
  const [editName, setEditName] = useState(''); 
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const thumbnailInputRef = useRef(null); // Ref for the thumbnail file input



  const handleVideoNameChange = async (videoId, newName) => {
    try {
      await updateDoc(doc(db, 'sections', sectionId, 'videos', videoId), { name: newName });
      // Update the local state
      setVideos(videos.map(v => {
        if (v.id === videoId) return { ...v, name: newName };
        return v;
      }));
    } catch (error) {
      console.error('Error updating video name:', error);
    }
  };

  const handleUpdateThumbnail = async (video, event) => {
    const file = event.target.files[0];
    if (!file) return;

    const confirmUpdate = window.confirm(`Are you sure you want to update the thumbnail for: ${video.name}?`);
    if (!confirmUpdate) return;

    try {
      setLoading(true);

      // Delete the old thumbnail from Firebase Storage
      if (video.thumbnailURL) {
        const oldThumbnailRef = ref(storage, video.thumbnailURL);
        await deleteObject(oldThumbnailRef);
      }

      // Upload the new thumbnail to Firebase Storage
      const newThumbnailRef = ref(storage, `thumbnails/${file.name}`);
      const uploadTask = await uploadBytes(newThumbnailRef, file);
      const newThumbnailURL = await getDownloadURL(uploadTask.ref);

      // Update the video document in Firestore
      await updateDoc(doc(db, 'sections', sectionId, 'videos', video.id), { thumbnailURL: newThumbnailURL });

      toast.success(`Thumbnail for ${video.name} updated successfully`);
      setLoading(false);

      // Update local state to reflect the new thumbnail URL
      setVideos(videos.map(v => {
        if (v.id === video.id) return { ...v, thumbnailURL: newThumbnailURL };
        return v;
      }));
    } catch (error) {
      toast.error(`Error occurred, thumbnail for ${video.name} was not updated`);
      console.error('Error updating thumbnail:', error);
      setLoading(false);
    }
  };

  const handleUpdateVideo = async (video, event) => {
    const file = event.target.files[0];
    if (!file) return;

    const confirmUpdate = window.confirm(`Are you sure you want to update the video file for: ${video.name}?`);
    if (!confirmUpdate) return;

    try {
      setLoading(true);

      // Delete the old video file from Firebase Storage
      const oldVideoRef = ref(storage, video.videoURL);
      await deleteObject(oldVideoRef);

      // Upload the new video file to Firebase Storage
      const newVideoRef = ref(storage, `videos/${file.name}`);
      const uploadTask = await uploadBytes(newVideoRef, file);
      const newVideoURL = await getDownloadURL(uploadTask.ref);

      // Update the video document in Firestore
      await updateDoc(doc(db, 'sections', sectionId, 'videos', video.id), { videoURL: newVideoURL });

      toast.success(`Video: ${video.name} updated successfully`);
      setLoading(false);

      // Update local state to reflect the new video URL
      setVideos(videos.map(v => {
        if (v.id === video.id) return { ...v, videoURL: newVideoURL };
        return v;
      }));
    } catch (error) {
      toast.error(`Error occurred, video: ${video.name} was not updated`);
      console.error('Error updating video:', error);
      setLoading(false);
    }
  };

  const handleSectionNameChange = (e) => {
    setEditName(e.target.value);
  };
  const handleSectionNameSave = async () => {
    if (section && editName.trim()) {
      try{
        await updateDoc(doc(db, 'sections', sectionId), { name: editName });
        toast.success(`Secion name: ${editName} saved successfully`);
        setSection({ ...section, name: editName });
      }
     catch(error) {
      toast.error(`Error occured, secion name did not saved`);
     }
    }
  };

  const handleChangeVideoIndex = async (videoId, oldIndex, newIndex) => {
    // Find if any video has the new index
    const targetVideo = videos.find(v => v.index === newIndex);
    if (targetVideo) {
      // Swap indices between the videos
      await updateDoc(doc(db, 'sections', sectionId, 'videos', videoId), { index: newIndex });
      await updateDoc(doc(db, 'sections', sectionId, 'videos', targetVideo.id), { index: oldIndex });

      // Update local state to reflect the changes
      setVideos(videos.map(v => {
        if (v.id === videoId) return { ...v, index: newIndex };
        if (v.id === targetVideo.id) return { ...v, index: oldIndex };
        return v;
      }).sort((a, b) => a.index - b.index));
    } else {
      alert('No video found with the specified index');
    }
  };
  const handleSectionOpenChange = async () => {
    const newOpenState = !section.open;
    await updateDoc(doc(db, 'sections', sectionId), { open: newOpenState });

    setSection({ ...section, open: newOpenState });
  };

  const handleDeleteVideo = async (video) => {
    if (window.confirm(`Are you sure you want to delete video: ${video.name}?`)) {
      try {
        // Delete video document from Firestore
        await deleteDoc(doc(db, 'sections', sectionId, 'videos', video.id));

        // Delete video file and thumbnail from Storage
        const videoRef = ref(storage, video.videoURL);
        const thumbnailRef = ref(storage, video.thumbnailURL);
        await deleteObject(videoRef);
        await deleteObject(thumbnailRef);

        // Update local state to remove the video
        toast.success(`Video: ${video.name} deleted successfully`);
        setVideos(videos.filter(v => v.id !== video.id));
      } catch (error) {
        toast.error(`Error occured, video: ${video.name} was not deleted`);
        console.error('Error deleting video:', error);
      }
    }
  };

  useEffect(() => {
    if (section) {
      setEditName(section.name);
    }
  }, [section]);

  useEffect(() => {
    const fetchSectionDetails = async () => {
      setLoading(true);
      const sectionDocRef = doc(db, 'sections', sectionId);
      const sectionDoc = await getDoc(sectionDocRef);
      if (sectionDoc.exists()) {
        setSection({ id: sectionDoc.id, ...sectionDoc.data() });
      }

      const querySnapshot = await getDocs(collection(db, 'sections', sectionId, 'videos'));
      setVideos(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      setLoading(false);
    };

    fetchSectionDetails();
  }, [sectionId]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="container sectionWrapper">
      <GoToPage path="/admintools/sections" text="Go to admin tools" />

      {section ? (
        <>
          <h2 className='containerContextTitle'>Name of section: {section.name}</h2>
          <div className='containerContext'>
            <div className='sectionNameWrapper'>
              <input
                className="inputField editName"
                type="text"
                value={editName}
                onChange={handleSectionNameChange}
                placeholder="Edit Section Name"
              />
              <div>
                <button
                  className="mainBtn saveNameBtn"
                  disabled={editName.length === 0}
                  onClick={handleSectionNameSave}
                >
                  Save Name
                </button>
              </div>
            </div>
            <div className="checkbox-container">
              <div
                className={`custom-checkbox${section.open ? ' checked' : ''}`}
                onClick={handleSectionOpenChange}
              ></div>
              <label className="checkbox-label">
                Is section open to all users
              </label>
              <input
                type="checkbox"
                className="checkbox-input"
                checked={section.open}
                onChange={() => {}}
              />
            </div>
            <div className='addVideoWrapper'>
              <button
                className="mainBtn"
                onClick={() => navigate(`/admintools/sections/${sectionId}/addvideo`)}
              >
                Add New Video
              </button>
            </div>
  <div>
      <h3 className='containerContextSubTitle'>Videos:</h3>
      <div className='videosWrapper'>
        {videos.length > 0 ? (
          videos.sort((a, b) => a.index - b.index).map((video) => (
            <div className='video' key={video.id}>
              <input
                className='inputField'
                type="text"
                value={video.name}
                onChange={(e) => handleVideoNameChange(video.id, e.target.value)}
              />
              <input
                    className='inputField'
                    type="number"
                    value={video.index}
                    onChange={(e) =>
                      handleChangeVideoIndex(video.id, video.index, parseInt(e.target.value))
                    }
                    />
              <div className='videoInputs'>
                    <button className="deleteBtn" onClick={() => handleDeleteVideo(video)}>
                      Delete Video
                    </button>

                    <input
                      type="file"
                      accept="video/*"
                      style={{ display: 'none' }}
                      onChange={(e) => handleUpdateVideo(video, e)}
                      ref={fileInputRef} // Attach the ref here
                    />

                    <button
                      className="generalBtn"
                      onClick={() => fileInputRef.current && fileInputRef.current.click()} // Use the ref here
                      >
                        Update Video
                      </button>

                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      onChange={(e) => handleUpdateThumbnail(video, e)}
                      ref={thumbnailInputRef}
                    />

                    {/* Button to trigger thumbnail file input */}
                    <button
                      className="generalBtn"
                      onClick={() => thumbnailInputRef.current && thumbnailInputRef.current.click()}
                    >
                      Update Thumbnail
                    </button>


                      </div>
                    </div>
                  ))
                ) : (
                  <p>No videos</p>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>Section not found</div>
      )}
       <ToastContainer
        theme="dark"
        position= "top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
    </div>
  );
};

export default SectionDetail;
