import React from 'react';
import { useNavigate } from 'react-router-dom';
import './VideoGallery.css';

function VideoGallery({ sections }) {
    const navigate = useNavigate();

    return (
        <div className="video-gallery">
            {sections.map((section) => (
                section.videos.length > 0 && (
                    <div key={section.id}> {/* Ensure each section has a unique key */}
                        <h2 className='videosListTitle'>{section.name}</h2>
                        <div className="videosListWrapper">
                            {section.videos.sort((a, b) => a.index - b.index).map(video => (
                                <div key={video.id} className="video-container" onClick={() => navigate(`/section/${section.id}/video/${video.id}`)}>
                                    {/* Each video also needs a unique key */}
                                    <p>{video.name}</p>
                                    <img src={video.thumbnailURL} alt={video.name} className="video-thumbnail" />
                                </div>
                            ))}
                        </div>
                    </div>
                )
            ))}
        </div>
    );
}

export default VideoGallery;
