import React from 'react'
import logo from '../../assets/logo.jpeg'
import AuthDetails from '../authDetails/AuthDetails';
import { Link } from "react-router-dom";

function Navbar() {
  return (
      <nav className='nav'>
            <ul className='nav-list'>
              <li>
                <Link to="/"><img className='logo' src={logo} alt="logo"/></Link>
              </li>
              <li>
                <AuthDetails />
              </li>
            </ul>
          </nav>
  )
}

export default Navbar;
