// AddVideo.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, storage } from '../../config/firebase-config';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, getDocs, doc, getDoc } from 'firebase/firestore';
import GoToPage from '../../components/goToPage/goToPage';
import './addVideo.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../../components/loading/Loading';

const AddVideo = () => {
  const { sectionId } = useParams();
  const navigate = useNavigate();
  const [videoForm, setVideoForm] = useState({name: '', file: null, thumbnail: null});
  const [section, setSection] = useState('');
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const formValid = videoForm.name.length > 0 && videoForm.file && videoForm.thumbnail;

  const handleVideoSubmit = async (e) => {
    e.preventDefault();
    if (!videoForm.name || !videoForm.file || !videoForm.thumbnail) {
      alert('Please fill in all fields and select files');
      return;
    }
    setIsUploading(true); // Start upload process
    try {
      // Upload video file
      const videoPath = `videos/${section.name}/${videoForm.name}/videoFile`;
      const videoRef = ref(storage, videoPath);
      await uploadBytes(videoRef, videoForm.file);
      const videoURL = await getDownloadURL(videoRef);

      // Upload thumbnail file
      const thumbnailPath = `videos/${section.name}/${videoForm.name}/thumbnailFile`;
      const thumbnailRef = ref(storage, thumbnailPath);
      await uploadBytes(thumbnailRef, videoForm.thumbnail);
      const thumbnailURL = await getDownloadURL(thumbnailRef);

      // Determine next video index
      const videosSnapshot = await getDocs(collection(db, 'sections', sectionId, 'videos'));
      const videoIndex = videosSnapshot.docs.length;

      // Create Firestore document
      await addDoc(collection(db, 'sections', sectionId, 'videos'), {
        name: videoForm.name,
        index: videoIndex,
        videoURL,
        thumbnailURL
      });
      setIsUploading(false); // End upload process
      toast.success(`Vidoe ${videoForm.name} saved successfully`);
      navigate(`/admintools/sections/${sectionId}`);
    } catch (error) {
      setIsUploading(false); // Ensure to stop the upload process in case of an error
      toast.error(`Error occur, video was not saved`);
      console.error('Error adding video:', error);
    }
  };

  useEffect(() => {
    const fetchSectionDetails = async () => {
      setLoading(true);
      const sectionDocRef = doc(db, 'sections', sectionId);
      const sectionDoc = await getDoc(sectionDocRef);
      if (sectionDoc.exists()) {
        setSection({ id: sectionDoc.id, ...sectionDoc.data() });
      }
      setLoading(false);
    };

    fetchSectionDetails();
  }, [sectionId]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='container'>
      <GoToPage path={`/admintools/sections/${sectionId}`} text="Go to section details" />
      <div>
        <h2 className='containerContextTitle'>Add video for section: {section.name}</h2>
        <form className='containerContext' onSubmit={handleVideoSubmit}>
          <div>
            <input
              className="inputField vidoeNameField"
              type="text"
              placeholder="Enter Video Name"
              name="name"
              value={videoForm.name}
              disabled={isUploading}
              onChange={(e) => setVideoForm((prevForm) => ({...prevForm, name: e.target.value}))}
            />
          </div>
          <div className='fileInputWrapper'>
            <label className='fileInputContainer'>
                <input
                    className='fileInput'
                    type="file"
                    name="file"
                    accept="video/*"
                    disabled={isUploading}
                    onChange={(e) => setVideoForm((prevForm) => ({ ...prevForm, file: e.target.files[0] }))}
                />
                 <div className={`fileInputLabel ${videoForm.file ? 'chosen' : ''}`}>
                    {videoForm.file ? (
                        <span>
                            {videoForm.file.name}
                            <br />
                            <label className='fileChosen'>File Chosen</label>
                        </span>
                    ) : (
                        <span>Choose Video</span>
                    )}
                </div>
            </label>
        </div>
        <div className='fileInputWrapper'>
            <label className='fileInputContainer'>
                <input
                    className='fileInput'
                    type="file"
                    name="thumbnail"
                    accept="image/*"
                    disabled={isUploading}
                    onChange={(e) => setVideoForm((prevForm) => ({ ...prevForm, thumbnail: e.target.files[0] }))}
                />
                <div className={`fileInputLabel ${videoForm.thumbnail ? 'chosen' : ''}`}>
                  {videoForm.thumbnail ? (
                      <span>
                          {videoForm.thumbnail.name}
                          <br />
                          <label className='fileChosen'>File Chosen</label>
                      </span>
                  ) : (
                      <span>Choose Thumbnail</span>
                  )}
              </div>
            </label>
          </div>
          <div>
            <button className='mainBtn' type='submit' disabled={!formValid || isUploading}>Add Video</button>
          </div>
        </form>
      </div>
      {isUploading && <div>Uploading video, please wait...</div>}
      <ToastContainer
        theme="dark"
        position= "top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
  </div>
  );
};

export default AddVideo;
