import React, { useState } from "react";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { auth, db } from "../../config/firebase-config";
import './auth.css';
import { checkValidations } from './validators';

const SignUp = ({ toggleShowSignUp }) => {
  const initialValue = { displayName: '', phone: '', email: '', password: '' };
  const [formData, setFormData] = useState(initialValue);
  const [errors, setErrors] = useState(initialValue);

  const handleFormInputChange = (e) => {
    setFormData((prevForm) => ({ ...prevForm, [e.target.name]: e.target.value }));
    setErrors((prevErrors) => ({ ...prevErrors, [e.target.name]: '' })); // Reset errors on input change
  };

  const signUp = (e) => {
    e.preventDefault();

    const { displayName, phone, email, password } = formData;
    const newErrors = checkValidations(email, password, displayName, phone);
    console.log(newErrors);
    if (newErrors) {
      setErrors(newErrors);
      return;
    }

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        updateProfile(userCredential.user, {
          displayName: displayName.trim(),
        }).then(() => {
          console.log("Profile updated successfully");

          const userDocRef = doc(db, "users", userCredential.user.uid);
          setDoc(userDocRef, {
            created_at: serverTimestamp(),
            permissions: "none",
            uid: userCredential.user.uid,
            email: email,
            displayName: displayName.trim(),
            phone: phone.trim(),
          });
        }).catch((error) => {
          console.error("Error updating user profile:", error);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="sign-container">
      <form onSubmit={signUp}>
        <h1>Create an account</h1>
        <input
          required
          className={errors.displayName ? 'formInput errorInput' : 'formInput'}
          type="text"
          placeholder="Enter your name"
          name="displayName"
          value={formData.displayName}
          onChange={(e) => handleFormInputChange(e)}
          autoComplete="off"
        />
        <p className={errors.displayName ? 'error' : 'noError'}>{errors.displayName}</p>
        <input
          required
          className={errors.phone ? 'formInput errorInput' : 'formInput'}
          type="text"
          placeholder="Enter your phone number"
          name="phone"
          value={formData.phone}
          onChange={(e) => handleFormInputChange(e)}
          autoComplete="off"
        />
        <p className={errors.phone ? 'error' : 'noError'}>{errors.phone}</p>
        <input
          required
          className={errors.email ? 'formInput errorInput' : 'formInput'}
          type="email"
          placeholder="Enter your email"
          name="email"
          value={formData.email}
          onChange={(e) => handleFormInputChange(e)}
          autoComplete="off"
        />
        <p className={errors.email ? 'error' : 'noError'}>{errors.email}</p>
        <input
          required
          className={errors.password ? 'formInput errorInput' : 'formInput'}
          type="password"
          placeholder="Enter your password"
          name="password"
          value={formData.password}
          onChange={(e) => handleFormInputChange(e)}
          autoComplete="off"
        />
        <p className={errors.password ? 'error' : 'noError'}>{errors.password}</p>
        <button className="mainBtn" type="submit">Sign Up</button>
      </form>
      <div>Already have an account? <span className="signUp" onClick={() => toggleShowSignUp()}>SIGN IN</span></div>
    </div>
  );
};

export default SignUp;