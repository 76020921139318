// AdminTools.js

import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, getDoc, deleteDoc, deleteUser, deleteField } from "firebase/firestore";
import { db, auth } from "../../config/firebase-config"; 
import { useNavigate } from 'react-router-dom';
const AdminTools = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdminStatus = async () => {


      if (!auth.currentUser) {
        // If no user is signed in, redirect to home or login page
        navigate('/');
        return;
      }

      const userDocRef = doc(db, "users", auth.currentUser.uid);
      const userDoc = await getDoc(userDocRef);


      if (userDoc.exists() && userDoc.data().permissions !== "admin") {
        // If the user is not an admin, redirect to home page
        navigate('/');
        return;
      }


    };

    checkAdminStatus();
  }, [navigate]);

  const handleEditSections = () => {
    navigate('/admintools/sections');
  };

  const handleAddNewVideo = () => {
    // Logic for 'Add New Video'
  };

  const handleDeleteVideo = () => {
    // Logic for 'Delete Video'
  };

  return (
    <div>
      <button onClick={handleEditSections}>Edit Sections</button>
      {/* <button onClick={handleAddNewVideo}>Add New Video</button>
      <button onClick={handleDeleteVideo}>Delete Video</button> */}
    </div>
  );
};

export default AdminTools;
