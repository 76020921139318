// Sections.js
import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc, deleteDoc, addDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { db, auth } from "../../config/firebase-config";
import './sections.css'; // Import the CSS module
import GoToPage from '../../components/goToPage/goToPage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../../components/loading/Loading';

const Sections = () => {
  const [sections, setSections] = useState([]);
  const [sectionName, setSectionName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleAddSection = async (e) => {
    e.preventDefault();
    if (!sectionName.trim()) {
      alert('Please enter a section name');
      return;
    }

    try {
      const sectionsCollection = collection(db, 'sections');
      const querySnapshot = await getDocs(sectionsCollection);
      const sectionIndex = querySnapshot.docs.length; // Number of existing documents

      const newSection = {
        name: sectionName,
        index: sectionIndex
      };

      await addDoc(sectionsCollection, newSection);

      // Add the new section to the local state
      setSections([...sections, { ...newSection, id: 'temp-id' }]);
      toast.success(`Section: ${sectionName} added successfully`);
      setSectionName('');
    } catch (error) {
      toast.error(`Error occured, section was not saved`);
      console.error('Error adding section:', error);
    }
  };


  const navigateToSection = (sectionId) => {
    navigate(`/admintools/sections/${sectionId}`);
  };

  const handleDeleteSection = async (event, section) => {
    event.stopPropagation();
    if (window.confirm(`Are you sure you want to delete section: ${section.name}?`)) {
      try {
        await deleteDoc(doc(db, 'sections', section.id));
        toast.success(`Section: ${section.name} deleted successfully`);
        setSections((prevSections) => prevSections.filter((sec) => sec.id !== section.id));
      } catch (error) {
        toast.error(`Error occurred, section: ${section.name} was not deleted`);
        console.error('Error deleting section:', error);
      }
    }
  };

  useEffect(() => {
    const checkAdminStatus = async () => {
        if (!auth.currentUser) {
          // If no user is signed in, redirect to home or login page
          navigate('/');
          return;
        }
        const userDocRef = doc(db, "users", auth.currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists() && userDoc.data().permissions !== "admin") {
          // If the user is not an admin, redirect to home page
          navigate('/');
          return;
        }
      };

    checkAdminStatus();

    const fetchSections = async () => {
      setIsLoading(true);
      const querySnapshot = await getDocs(collection(db, 'sections'));
      const fetchedSections = querySnapshot.docs
        .map(doc => ({ ...doc.data(), id: doc.id }))
        .sort((a, b) => a.index - b.index); // Sort sections by index
      setSections(fetchedSections);
      setIsLoading(false);
    };

    fetchSections();
  }, [navigate]);

  if (isLoading) {
    return <Loading />;
}

  return (
    <div className="container">
      <GoToPage />
      <form className="addSectionForm" onSubmit={handleAddSection}>
        <div>
          <input
            className="inputField inputNameField"
            type="text"
            placeholder="Section Name"
            value={sectionName}
            onChange={(e) => setSectionName(e.target.value)}
          />
        </div>
        <button type='submit' className="mainBtn addSection" disabled={sectionName.length === 0}>Add Section</button>
      </form>
      <h2>List of sections:</h2>
      <ul className="sectionList">
        {sections.map((section) => (
          <li key={section.id} className="sectionItem" onClick={() => navigateToSection(section.id)}>
            <span>{`${section.index + 1}: ${section.name}`}</span>
            <button className="deleteBtn" onClick={(event) => handleDeleteSection(event, section)}>
              Delete Section
            </button>
          </li>
        ))}
      </ul>
      <ToastContainer
        theme="dark"
        position= "top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
    </div>
  );
};

export default Sections;
