import React, { useState, useEffect } from 'react';
import { db, auth  } from '../../config/firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, addDoc, orderBy, serverTimestamp, getDocs, getDoc, doc, deleteDoc } from 'firebase/firestore';
import './CommentsDialog.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CommentsDialog = ({ videoId, onClose }) => {
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [userPermission, setUserPermission] = useState(null);
    const isAdmin = userPermission === "admin";
    const isPremium = userPermission === "premium";

    const handleDeleteComment = async (commentId) => {
        if (isAdmin && window.confirm(`Are you sure you want to delete this comment?`)) {
            try {
                await deleteDoc(doc(db, "comments", commentId));
                setComments(comments.filter(comment => comment.id !== commentId));
                toast.success("Comment deleted successfully");
            } catch (error) {
                toast.error("Error occurred: unable to delete comment");
                console.error('Error deleting comment:', error);
            }
        }
    };

    const handleCommentSubmit = async () => {
        if (!auth.currentUser) {
            toast.error("You must be logged in to comment");
            return;
        }
        if (!newComment.trim()) {
            toast.error("Please enter a comment");
            return;
        }

        try {
            const commentData = {
                commenter_name: auth.currentUser.displayName,
                commenter_uid: auth.currentUser.uid,
                comment: newComment,
                commented_on: serverTimestamp(),
                video: videoId
            };

            const newCommentRef = await addDoc(collection(db, "comments"), commentData);
            const newCommentSnapshot = await getDoc(newCommentRef);
            setComments([ { id: newCommentRef.id, ...newCommentSnapshot.data() }, ...comments ]);
            setNewComment('');
            toast.success(`Your comment saved successfully`);
        }
        catch (error) {
            toast.error("Error occured: your comment did not saved");
            console.log('error');
        }
    };

    useEffect(() => {
        const fetchComments = async () => {
            const commentsRef = collection(db, "comments");
            const q = query(commentsRef, where("video", "==", videoId), orderBy("commented_on", "desc"));
            const querySnapshot = await getDocs(q);
            const commentsArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setComments(commentsArray);
        };
        // Check if the current user is an admin or premium
        const checkIfAdminOrPremium = async (user) => {
            if (user) {
                const userDocRef = doc(db, "users", user.uid);
                const docSnap = await getDoc(userDocRef);
                if (docSnap.exists() && (docSnap.data().permissions === "admin" || docSnap.data().permissions === "premium")) {
                    setUserPermission(docSnap.data().permissions);
                } else {
                    setUserPermission(null);
                }
            } else {
                setUserPermission(null);
            }
        };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
        checkIfAdminOrPremium(user);
    });



        fetchComments();
        return () => unsubscribe();
    }, [videoId]);

    return (
        <>
        <div className="commentsWrapper">
            <h2>Comments:</h2>
            {comments.length > 0 ? (
                comments.map((comment, index) => (
                    <div key={index} className="comments">
                        <p>{comment.comment}</p>
                        <span className='commentBy'>Commented by: {comment.commenter_name}</span>
                        {isAdmin && (
                            <button
                                className="deleteBtn"
                                onClick={() => handleDeleteComment(comment.id)}
                            >
                                Delete
                            </button>
                        )}
                    </div>
                ))
                ) : (
                    <p>There are no coments for this video</p>
                )}
                {(isAdmin || isPremium) && (
                    <div className='addCommentWrapper'>
                        <textarea
                            className='addCommentTrxtArea'
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            placeholder="Write your comment here..."
                        />
                        <div className='addCommentButtonsWrapper'>
                            <button className='mainBtn' disabled={newComment.length === 0} onClick={handleCommentSubmit}>Submit comment</button>
                            <button className='generalBtn' onClick={onClose}>Close comments</button>
                        </div>
                    </div>
                )}
            </div>
            <ToastContainer
            theme="dark"
            position= "top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
        </>
    );
};

export default CommentsDialog;
