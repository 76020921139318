const validateName = (name) => {
    // Name should be more than 2 letters and should not contain numbers
    return /^[a-zA-Z]{3,}$/.test(name);
  };

const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const validatePassword = (password) => {
    // Password should be at least 4 characters
    return password.length >= 4;
};

const validatePhone = (phone) => {
    // Phone number is considered valid if it contains only digits and has at least 5 digits
    return /^[0-9]{5,}$/.test(phone);
};

export const checkValidations = (email, password, displayName, phone) => {
    const errors = {};
    if (!validateEmail(email)) {
      errors.email = 'Invalid email format';
    }
    if (!validatePassword(password)) {
      errors.password = 'Password must be at least 4 characters';
    }
    if(displayName && !validateName(displayName)) {
      errors.displayName = 'Name should be more than 2 letters with no numbers';
    }
    if(phone && !validatePhone(phone)) {
        errors.phone = 'Invalid or empty phone number';
    }
    return Object.keys(errors).length > 0 ? errors : null;
  };