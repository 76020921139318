import React from 'react'
import { Link } from 'react-router-dom';
import './goToPage.css';
import { FaArrowLeftLong } from "react-icons/fa6";

const GoToPage = ({path = "/", text = "Back to home page"}) => {
  return (
    <div className='goBackWrapper'>
        <Link to={path} className='goBack'>
            <FaArrowLeftLong /> {text}
        </Link>
    </div>
  )
}

export default GoToPage
